exports.components = {
  "component---src-pages-car-form-jsx": () => import("./../../../src/pages/carForm.jsx" /* webpackChunkName: "component---src-pages-car-form-jsx" */),
  "component---src-pages-cars-and-quote-jsx": () => import("./../../../src/pages/cars-and-quote.jsx" /* webpackChunkName: "component---src-pages-cars-and-quote-jsx" */),
  "component---src-pages-long-time-rental-jsx": () => import("./../../../src/pages/long-time-rental.jsx" /* webpackChunkName: "component---src-pages-long-time-rental-jsx" */),
  "component---src-pages-ssr-jsx": () => import("./../../../src/pages/ssr.jsx" /* webpackChunkName: "component---src-pages-ssr-jsx" */),
  "component---src-templates-about-us-and-our-team-jsx": () => import("./../../../src/templates/aboutUsAndOurTeam.jsx" /* webpackChunkName: "component---src-templates-about-us-and-our-team-jsx" */),
  "component---src-templates-car-form-jsx": () => import("./../../../src/templates/CarForm.jsx" /* webpackChunkName: "component---src-templates-car-form-jsx" */),
  "component---src-templates-contact-and-location-jsx": () => import("./../../../src/templates/contactAndLocation.jsx" /* webpackChunkName: "component---src-templates-contact-and-location-jsx" */),
  "component---src-templates-frequent-answer-and-question-jsx": () => import("./../../../src/templates/frequentAnswerAndQuestion.jsx" /* webpackChunkName: "component---src-templates-frequent-answer-and-question-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-insurance-jsx": () => import("./../../../src/templates/insurance.jsx" /* webpackChunkName: "component---src-templates-insurance-jsx" */),
  "component---src-templates-on-construction-page-jsx": () => import("./../../../src/templates/onConstructionPage.jsx" /* webpackChunkName: "component---src-templates-on-construction-page-jsx" */),
  "component---src-templates-our-cars-and-reservation-jsx": () => import("./../../../src/templates/ourCarsAndReservation.jsx" /* webpackChunkName: "component---src-templates-our-cars-and-reservation-jsx" */),
  "component---src-templates-road-safety-jsx": () => import("./../../../src/templates/roadSafety.jsx" /* webpackChunkName: "component---src-templates-road-safety-jsx" */),
  "component---src-templates-testimonials-jsx": () => import("./../../../src/templates/testimonials.jsx" /* webpackChunkName: "component---src-templates-testimonials-jsx" */),
  "component---src-templates-using-dsg-jsx": () => import("./../../../src/templates/using-dsg.jsx" /* webpackChunkName: "component---src-templates-using-dsg-jsx" */)
}

